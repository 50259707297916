<!--
 * @Autor: oops.liu
-->
<template>
  <div class="top_box">
    <div class="top_bg">
      <img :src="sellerinfo.backImg" alt="" />
      <i class="bg_color"></i>
    </div>
    <div class="top_content">
      <van-nav-bar
        title="标题"
        left-arrow
        :border="false"
        @click-left="onClickLeft"
      >
        <template #title>
          <van-search
            v-model="value"
            placeholder="请输入搜索关键词"
            shape="round"
            readonly
            @click.native="search"
          />
        </template>
        <template #right>
          <div class="nav_dot">
            <van-popover
              v-model="showPopover"
              trigger="click"
              :actions="actions"
              @select="onSelect"
              placement="bottom-end"
            >
              <template #reference>
                <van-badge
                  :content="badge > 0 ? badge : ''"
                  max="99"
                  color="#fff"
                >
                  <img src="~@/assets/img/shop/dot.png" alt="" />
                </van-badge>
              </template>
            </van-popover>
          </div>
        </template>
      </van-nav-bar>
      <div class="seller_bar flex_sb" @click="seller">
        <div class="logo">
          <img :src="sellerinfo.imgUrl" alt="" />
        </div>
        <div class="seller_info">
          <p class="seller_name">{{ sellerinfo.name }}</p>
          <p class="seller_hint">{{ sellerinfo.stars | starsFilter }}人关注</p>
        </div>
        <div class="seller_btn">
          <van-button class="follow" @click.stop="follow"  v-if="sellerinfo.focusStatus=='0'">关注店铺</van-button>
          <van-button class="follow" @click.stop="cancle"  v-if="sellerinfo.focusStatus=='1'">已关注</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState,mapActions } from "vuex";
export default {
  components: {},
  props: {
    sellerinfo: {
      type: Object,
      required: true,
    },
    // badge: {
    //   type: Number,
    //   default: 0,
    // },
  },
  data() {
    return {
      bg: require("@/assets/img/shop/bg.png"),
      value: "",
      showPopover: false,
      token:sessionStorage.getItem("token"),
    };
  },
  computed: {
    badge() {
      return this.$store.state.seller.msglength;
    },
    actions() {
      const list = [
        {
          icon: require("@/assets/img/message.png"),
          text: `消息（${this.badge}）`,
          event: "message",
        },
        { icon: require("@/assets/img/s2.png"), text: "分享", event: "share" },
        { icon: require("@/assets/img/h2.png"), text: "首页", event: "toHome" },
        {
          icon: require("@/assets/img/shop.png"),
          text: "店铺详情",
          event: "detail",
        },
      ];
      return list;
    },
  },
  mounted() {
			var u = navigator.userAgent;
			var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android
			var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios
			if (isAndroid) {
				window.android.getToken("get"); // 向android传参
			} else {
				window.webkit.messageHandlers.getToken.postMessage("getToken"); // 向ios传参
			}
				window.jsGetToken = this.jsGetToken;
		},
  methods: {
    ...mapActions({
      ifFocusShop: "seller/ifFocusShop",
    }),
    //安卓调用js  获取token
			jsGetToken(msg) {
				sessionStorage.setItem("token", msg)
			},
    follow() {
      this.$emit("follow");
    },
    onClickLeft() {
      this.$emit("left");
    },
    search() {
      this.$emit("search");
    },
    seller() {
      this.$emit("details");
    },
    // 取消关注
    cancle() {
      this.ifFocusShop({
        shopId:this.$route.query.shopId,
        status:'2',//状态 1：关注 2：取消关注
    })
    location. reload()
this.$router.go(0)
    },
      // this.$emit("cancle");
    onSelect(action) {
      this.showPopover = false;
      this.$emit("right", { action });
    },
  },

  filters: {
    starsFilter(stars) {
      if (stars > 10000) {
        return parseInt(stars / 10000) + "万";
      } else {
        return stars;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.top_box {
  position: relative;
  width: 100%;
  height: 224px;
  .top_bg {
    position: absolute;
    height: 224px;
    width: 100%;
    z-index: 1;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }

    .bg_color {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(rgba(#000, 0.7), rgba(#fff, 0.3));
    }
  }
  .top_content {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    ::v-deep .van-nav-bar {
      background: transparent;

      .van-nav-bar__left {
        .van-icon {
          color: #fff;
        }
      }

      .nav_dot {
        width: 20px;
        display: flex;
        align-items: center;
        position: relative;
        .van-badge__wrapper {
          .van-badge {
            color: #f00;
            border-radius: 7px 7px 7px 0px;
          }
        }
        img {
          width: 20px;
          height: 5px;
        }
      }
    }
    ::v-deep .van-search {
      background: rgba($color: #fff, $alpha: 0.3);
      border-radius: 16px;
      overflow: hidden;
      padding: 0 15px;

      .van-search__content {
        background: transparent;
      }

      .van-icon {
        color: #fff;
      }
    }
    .seller_bar {
      box-sizing: border-box;
      width: 100%;
      padding: 5px 18px;

      .logo {
        width: 40px;
        height: 40px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          overflow: hidden;
        }
      }

      .seller_info {
        flex: 1;
        padding-left: 8px;
        color: #fff;

        .seller_name {
          font-size: 16px;
        }

        .seller_hint {
          font-size: 12px;
        }
      }

      .seller_btn {
        width: 70px;
        padding-top: 7px;

        .follow {
          width: 100%;
          height: 25px;
          background: linear-gradient(-36deg, #f11769, #fd5f0d);
          border-radius: 13px;
          color: #fff;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
